import { useEffect, useState, Component } from 'react';
import './App.css';
import contract from './Contracts/BelugaBabies.json';
import wEther from './Contracts/WrappedEth.json';
import { ethers } from 'ethers';
//mumbai: 0x31030Daf8e8F7D6862f191BbF9dB2Cafaf52F58A
//main: 0xDA0ABe4C6444C19Eca22688C51368D9a95b16a71
//0xDA0ABe4C6444C19Eca22688C51368D9a95b16a71
const contractAddress = "0x8317A6515DD8Ee53B8a89666851bBeFbef9c6444";
const wEthAddress = "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619";
const abi = contract.abi;
const wEthAbi = wEther.abi;

const deviceType = () => {
    const ua = navigator.userAgent;
    if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
        return "tablet";
    }
    else if (/Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(ua)) {
        return "mobile";
    }
    return "desktop";
};

const App = () => {

    const [currentAccount, setCurrentAccount] = useState(null);
    
    const { ethereum } = window;
        const provider = new ethers.providers.Web3Provider(window.ethereum, "any");
        provider.on("network", (newNetwork, oldNetwork) => {
            // When a Provider makes its initial connection, it emits a "network"
            // event with a null oldNetwork along with the newNetwork. So, if the
            // oldNetwork exists, it represents a changing network
            if (oldNetwork) {
                window.location.reload();
            }
            //console.log(newNetwork);
        });
        const signer = provider.getSigner();
        const nftContract = new ethers.Contract(contractAddress, abi, signer);
        const wEthContract = new ethers.Contract(wEthAddress, wEthAbi, signer);
        var mintFigure = 0;
    let maxMint = 0;
    let totMint = 0;
        let currentPrice = 0;
        let wEthPrice = 0;
    
    async function mintFigures() {
        if (ethereum) {
            document.getElementById("install").innerHTML = ("");
            console.log(nftContract);
            mintFigure = await nftContract.totalSupply();
            let nftPrice = "";
            nftPrice = await nftContract.PRICE();
            let ethPrice = "";
            ethPrice = await nftContract.PRICEWETH();
            maxMint = await nftContract.MAX_PER_MINT();
            nftPrice = nftPrice / 1000000000000000000;
            ethPrice = ethPrice / 1000000000000000000;
            currentPrice = nftPrice;
            wEthPrice = ethPrice;
            totMint = await nftContract.MAX_SUPPLY();
            document.getElementById('total').innerHTML = ((g * currentPrice) + " $MATIC / " + (g * wEthPrice).toPrecision(4) + " $wETH");
            //document.getElementById("price").innerHTML = (nftPrice + " $MATIC ("+ethPrice+"$wETH) Each!<br><br>");
            document.getElementById("progress").innerHTML = ("<h2>" + mintFigure + "/"+totMint+" Minted!</h2>");
        } else if (deviceType == "desktop") {
            document.getElementById("demo").innerHTML = ("<h2>OOPS</h2>");
        } else {
            document.getElementById("demo").innerHTML = ("<h2>OOPS</h2>");
        }
        
    }
    var g = 1;
    //var curNetwork = provider.getNetwork();
    //var netName = curNetwork.name;
    
    const checkWalletIsConnected = async () => {
        // const { ethereum } = window;

        if (!ethereum) {
            //alert("Please install Metamask!");
        } else {
            console.log("Wallet exists! We're ready to go!");

            var selAdd = await ethereum.request({ method: 'eth_accounts' });

            if (selAdd.length !== 0) {
                console.log(selAdd[0]);
                setCurrentAccount(selAdd[0]);

                if (mintFigure == 0) { mintFigures(); }


            } else {
                console.log("No authorised account was found.")
            }

            var curNetwork = await provider.getNetwork();
            var netName = curNetwork.chainId;
            if (netName != 137 && netName != 80001) {
                document.getElementById("demo").innerHTML = "<div style='color:red;'> Please make sure you are connected to the Polygon Network!</div>";
            }


        }

    }
    //maxMint = 4;
    mintFigures();
    console.log(maxMint);

    const upOrDown = () => {
        if (g < maxMint) {
            g = g+ 1;
        }
        let nftPrice = currentPrice;
        document.getElementById('up').innerHTML = ("Mint " + g + " NFTs");
        document.getElementById('total').innerHTML = ((g * currentPrice) + " $MATIC / " + (g * wEthPrice).toPrecision(4) + " $wETH");
        console.log(g);
        return;
    }
    const downJustDown = () => {
        if (g > 1) {
            g = g- 1;
        }
        document.getElementById('up').innerHTML = ("Mint " + g + " NFTs");
        document.getElementById('total').innerHTML = ((g * currentPrice) + " $MATIC / " + (g * wEthPrice).toPrecision(4)+" $wETH");
        console.log(g);
        return;
    }

    const connectWalletHandler = async () => {
        if (!ethereum) {
            alert("Please install Metamask!");
        }

        try {
            const accounts = await ethereum.request({ method: 'eth_requestAccounts' });
            console.log("Found an account! Address: ", accounts[0]);
            setCurrentAccount(accounts[0]);
            if (mintFigure == 0) { mintFigures(); }
        } catch (err) {
            document.getElementById("demo").innerHTML = "<div style='color:red;'>"+(err)+" </div>";
        }
    }


    const mintNftHandler = async () => {
        try {
            if (ethereum) {
                var curNetwork = await provider.getNetwork();
                var netName = curNetwork.chainId;
                if (netName == 137 || netName == 80001) {
                    var mintNum = g;
                    console.log(mintNum);
                    let nftPrice = currentPrice;
                    console.log(nftPrice);
                    document.getElementById("log").innerHTML = ("Initialise payment...<br>" + document.getElementById("log").innerHTML);
                    let nftTxn = await nftContract.mintNFTs(mintNum, { value: ethers.utils.parseEther((nftPrice*mintNum).toString()) });
                    console.log("Hmm...");
                    document.getElementById("log").innerHTML = ("Waiting for transaction...<br>" + document.getElementById("log").innerHTML);
                    await nftTxn.wait();
                    document.getElementById("log").innerHTML = ("Success! See transaction <a href='https://polygonscan.com/tx/" + nftTxn.hash + "' >here.</a><br>" + document.getElementById("log").innerHTML);
                } else {
                    document.getElementById("demo").innerHTML = "<div style='color:red;'> Please make sure you are connected to the Polygon Network!</div>";
                }
            } else {
                console.log("Ethereum object does not exist");
            }

        } catch (err) {
            if (err.data != null) {
                document.getElementById("demo").innerHTML = err.data.message;
            }

        }

    }

    const mintNftHandlerETH = async () => {

        try {
            if (ethereum) {
                var curNetwork = await provider.getNetwork();
                var netName = curNetwork.chainId;
                if (netName == 137 || netName == 80001) {
                    var mintNum = g;
                    console.log(mintNum);
                    let ethPrice = "";
                    ethPrice = await nftContract.PRICEWETH();
                    ethPrice = ethPrice / 1000000000000000000;
                    document.getElementById("log").innerHTML = ("Finding wETH Contract...<br>" + document.getElementById("log").innerHTML);
                    let abib = ["function approve(address _spender, uint256 _value) public returns (bool success)"]
                    let cont = new ethers.Contract(wEthAddress, abib, signer)
                    document.getElementById("log").innerHTML = ("Getting permission...<br>" + document.getElementById("log").innerHTML);
                    await cont.approve(contractAddress, ethers.utils.parseEther((ethPrice * mintNum).toString()));
                    document.getElementById("log").innerHTML = ("Initialise payment...<br>" + document.getElementById("log").innerHTML);
                    let nftTxn = await nftContract.mintWETH(mintNum);
                    console.log("Hmm...");
                    document.getElementById("log").innerHTML = ("Waiting for transaction...<br>" + document.getElementById("log").innerHTML);
                    await nftTxn.wait();
                    document.getElementById("log").innerHTML = ("Success! See transaction <a href='https://polygonscan.com/tx/" + nftTxn.hash + "' >here.</a><br>" + document.getElementById("log").innerHTML);
                } else {
                    document.getElementById("demo").innerHTML = "<div style='color:red;'> Please make sure you are connected to the Polygon Network!</div>";
                }
            } else {
                console.log("Ethereum object does not exist");
            }

        } catch (err) {
            if (err.data != null) {
                document.getElementById("demo").innerHTML = err.data.message;
            }

        }

    }
    const connectWalletButton = () => {
        return (
            <button onClick={connectWalletHandler} className='cta-button connect-wallet-button'>
                Connect Wallet
            </button>
        )
    }

    const mintNftButton = () => {
        var mintNum = g;
        let nftPrice = currentPrice;
        return (
            <>
                <button onClick={mintNftHandler} className='cta-button mint-nft-button'>
                    Mint with $MATIC
                </button>
                <button onClick={mintNftHandlerETH} className='cta-button mint-nft-button'>
                    Mint with $wETH
                </button>
            </>
        )
    }

    const incMint = () => {
        return (
            <button onClick={upOrDown} value={true} className='cta-button plus-minus-button'>
                +
            </button>
        )
    }
    const decMint = () => {
        return (
            <button onClick={downJustDown} value={false} className='cta-button plus-minus-button'>
                -
            </button>
        )
    }
    useEffect(() => {
        checkWalletIsConnected();
    }, [])

    return (
        <div className='main-app' id="main-app">            
            <label htmlFor="num">How many to mint:</label>
            <br></br>
            {decMint()}     <label id="up" className="">Mint {g} NFTs</label>   {incMint()}
            <br></br><div id="total">{(g * currentPrice)} $MATIC/ {(g * wEthPrice)} $wETH</div>
            <br></br>
            <div id="demo">
                {currentAccount ? mintNftButton() : connectWalletButton()}
            </div>
            <div id="progress"></div>
        </div>
    )
}

export default App;